import React, { useEffect, useState } from 'react';

const Home = () => {
  const [cars, setCars] = useState([]);

  useEffect(() => {
    const fetchCars = async () => {
      try {
        const response = await fetch('/get_cars.php');
        const data = await response.json();
        if (data.success) {
          setCars(data.cars);
        } else {
          alert('Failed to fetch cars');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchCars();
  }, []);

  return (
    <div>
      <h2>Cars for Hire</h2>
      <div className="car-list">
        {cars.map(car => (
          <div key={car.id} className="car-item">
            <h3>{car.name}</h3>
            <p>Model: {car.model}</p>
            <p>Price: ${car.price}</p>
            <p>Status: {car.status === 'available' ? 'Available' : 'Unavailable'}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Home;
