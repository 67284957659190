import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.clear();
    navigate('/login');
  };

  useEffect(() => {
    const isAuthenticated = sessionStorage.getItem('isAuthenticated');

    if (!isAuthenticated) {
      alert('Please log in to access the dashboard');
      navigate('/login');
      return;
    }

    const fetchUserData = async () => {
      try {
        const response = await fetch('/dashboard.php');
        const data = await response.json();

        if (data.success) {
          setUser(data);
        } else {
          alert(data.message);
          navigate('/login');
        }
      } catch (error) {
        console.error('Error:', error);
        navigate('/login');
      }
    };

    fetchUserData();
  }, [navigate]);

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2>Dashboard</h2>
      <p>Welcome, {user.email}!</p>
      <p>Your user ID is: {user.user_id}</p>
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
};

export default Dashboard;
