import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Login from './Login';
import Dashboard from './Dashboard';
import ManageCars from './ManageCars';
import NotFound from './NotFound'; // Optional: For handling 404 pages

const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/manage-cars" element={<ManageCars />} />
      <Route path="*" element={<NotFound />} /> {/* Optional: Handle undefined routes */}
    </Routes>
  </Router>
);

export default App;
