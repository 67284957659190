import React from 'react';

const NotFound = () => (
  <div>
    <h2>404 - Page Not Found</h2>
    <p>Oops! The page you are looking for does not exist.</p>
  </div>
);

export default NotFound;
